import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FaInstagram,
  FaTiktok,
  FaPlay,
  FaStop,
  FaCog,
  FaTimes,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/header';
import styles from './bot-extrator.module.css';

const BotExtrator = () => {
  const [extractionConfig, setExtractionConfig] = useState({
    platforms: {
      instagram: true,
      tiktok: false,
    },
    minFollowers: '',
    minEngagementRate: '',
    brandDescription: '',
  });

  const [isRunning, setIsRunning] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [statusInfo, setStatusInfo] = useState({
    influencersFound: 0,
    runningTime: 0,
  });
  const [hashtags, setHashtags] = useState([]);


const handleHashtagInput = (e) => {
  if (e.key === 'Enter' || e.key === ',') {
    e.preventDefault();
    const value = e.target.value.trim();
    if (value && !hashtags.includes(value) && hashtags.length < 5) {
      setHashtags([...hashtags, value]);
      e.target.value = '';
    }
  }
};

const removeHashtag = (index) => {
  setHashtags(hashtags.filter((_, i) => i !== index));
};


  const fetchExtractionConfig = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/admin/extractor/config`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.config) {
          const { hashtags, ...otherConfig } = response.data.config.value;
          setExtractionConfig(otherConfig);
          setHashtags(hashtags || []);
        }
    } catch (error) {
      console.error('Erro ao obter a configuração da extração:', error);
    }
  };

  const saveConfig = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_DATABASE}/admin/extractor/config`,
        { ...extractionConfig, hashtags },
        {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowSettings(false);
      toast.success('Configurações salvas com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar a configuração:', error);
      toast.error('Erro ao salvar as configurações. Tente novamente.');
    }
  };

  const fetchExtractionStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/admin/extractor/current`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.extraction) {
        const { isRunning, influencersFound, runningTime } =
          response.data.extraction;
        setIsRunning(isRunning);
        setStatusInfo({
          influencersFound,
          runningTime,
        });
      } else {
        setShowSettings(true);
        setIsRunning(false);
        setStatusInfo({
          influencersFound: 0,
          runningTime: 0,
        });
      }
    } catch (error) {
      console.error('Erro ao obter o status da extração:', error);
    }
  };

  const toggleExtraction = async () => {
    try {
      if (isRunning) {
        await axios.post(`${process.env.REACT_APP_DATABASE}/admin/extractor/stop`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setIsRunning(false);
        setShowSettings(true);
        toast.info('Extração parada com sucesso.');
      } else {
        await axios.post(`${process.env.REACT_APP_DATABASE}/admin/extractor/start`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setIsRunning(true);
        setShowSettings(false);
        toast.success('Extração iniciada com sucesso!');
      }
      fetchExtractionStatus();
    } catch (error) {
      console.error('Erro ao alternar a extração:', error);
      toast.error('Erro ao alternar a extração. Tente novamente.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExtractionConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePlatformChange = (platform) => {
    setExtractionConfig((prev) => ({
      ...prev,
      platforms: {
        ...prev.platforms,
        [platform]: !prev.platforms[platform],
      },
    }));
  };

  const formatRunningTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hrs}h ${mins}min`;
  };

  useEffect(() => {
    fetchExtractionConfig();
    fetchExtractionStatus();

    const interval = setInterval(() => {
      if (isRunning) {
        setStatusInfo((prev) => ({
          ...prev,
          runningTime: prev.runningTime + 60,
        }));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [isRunning]);

  return (
    <div className={styles.botExtratorMain}>
      <Header />
      <div className={styles.botExtratorContainer}>
        <h1 className={styles.botExtratorTitle}>
          Bot Extrator de Influenciadores
        </h1>
        <p className={styles.botExtratorDescription}>
          Configure e inicie o bot para extrair influenciadores para sua marca
          de forma contínua e automatizada.
        </p>

        <div className={styles.controlPanel}>
          <button
            className={`${styles.controlButton} ${
              isRunning ? styles.active : ''
            }`}
            onClick={toggleExtraction}
          >
            {isRunning ? (
              <>
                <FaStop /> Parar
              </>
            ) : (
              <>
                <FaPlay /> Iniciar
              </>
            )}
          </button>
          <button
            className={`${styles.settingsButton} ${
              showSettings ? styles.active : ''
            }`}
            onClick={() => setShowSettings(!showSettings)}
          >
            <FaCog />
            Configurações
          </button>
        </div>

        {showSettings && (
          <div className={styles.configSection}>
            <button
              className={styles.closeButton}
              onClick={() => setShowSettings(false)}
            >
              <FaTimes />
            </button>
            <h2 className={styles.configTitle}>Configurações</h2>
            <div className={styles.inputGroup}>
              <label>Plataformas</label>
              <div className={styles.platformButtons}>
                <button
                  className={`${styles.platformButton} ${
                    extractionConfig.platforms.instagram ? styles.active : ''
                  }`}
                  onClick={() => handlePlatformChange('instagram')}
                >
                  <FaInstagram /> Instagram
                </button>
                <button
                  className={`${styles.platformButton} ${
                    extractionConfig.platforms.tiktok ? styles.active : ''
                  }`}
                  onClick={() => handlePlatformChange('tiktok')}
                >
                  <FaTiktok /> TikTok
                </button>
              </div>
            </div>
            <div className={styles.configGrid}>
              <div className={styles.inputGroup}>
                <label htmlFor="minFollowers">Mínimo de Seguidores</label>
                <input
                  type="number"
                  id="minFollowers"
                  name="minFollowers"
                  value={extractionConfig.minFollowers}
                  onChange={handleInputChange}
                  min="0"
                  className={styles.input}
                  placeholder="Ex: 10000"
                />
                <p className={styles.inputHint}>
                  Pode reduzir a quantidade de influenciadores extraídos.
                </p>
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="minEngagementRate">
                  Taxa Mínima de Engajamento (%)
                </label>
                <input
                  type="number"
                  id="minEngagementRate"
                  name="minEngagementRate"
                  value={extractionConfig.minEngagementRate}
                  onChange={handleInputChange}
                  min="0"
                  max="100"
                  step="0.01"
                  className={styles.input}
                  placeholder="Ex: 2.5"
                />
                <p className={styles.inputHint}>
                  Pode reduzir a quantidade de influenciadores extraídos.
                </p>
              </div>
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="brandDescription">Descrição da Marca</label>
              <textarea
                id="brandDescription"
                name="brandDescription"
                value={extractionConfig.brandDescription}
                onChange={handleInputChange}
                className={styles.textarea}
                placeholder="Descreva detalhadamente o que sua marca faz, seus produtos/serviços, etc."
                maxLength={4000}
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="hashtags">Hashtags (até 5)</label>
              <div className={styles.hashtagInput}>
                <input
                  type="text"
                  id="hashtags"
                  onKeyDown={handleHashtagInput}
                  placeholder="Digite uma hashtag e pressione Enter"
                  maxLength={30}
                />
                <div className={styles.hashtagList}>
                  {hashtags.map((tag, index) => (
                    <span key={index} className={styles.hashtagItem}>
                      #{tag}
                      <button onClick={() => removeHashtag(index)}>&times;</button>
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <button className={styles.saveButton} onClick={saveConfig}>
              Salvar Configurações
            </button>
          </div>
        )}

        {isRunning && (
          <div className={styles.statusPanel}>
            <h2 className={styles.statusTitle}>Status da Extração</h2>
            <div className={styles.statusInfo}>
              <p>
                Estado:{' '}
                <span className={styles.running}>
                  {isRunning ? 'Em execução' : 'Parado'}
                </span>
              </p>
              <p>
                Influenciadores encontrados:{' '}
                <span className={styles.count}>
                  {statusInfo.influencersFound}
                </span>
              </p>
              <p>
                Tempo em execução:{' '}
                <span className={styles.time}>
                  {formatRunningTime(statusInfo.runningTime)}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BotExtrator;