import React from 'react';
import { FaWhatsapp, FaLock, FaUnlock } from 'react-icons/fa';
import styles from './whatsapp-gatekeeper.module.css';

const WhatsAppGatekeeper = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.logoContainer}>
          <img src="/logo.png" alt="Logo" className={styles.logo} />
        </div>
        <h1 className={styles.title}>Bem-vindo à nossa comunidade de influenciadores!</h1>
        <div className={styles.messageContainer}>
          <FaLock className={styles.icon} />
          <p className={styles.message}>
            Sua conta está temporariamente suspensa até que você se junte ao nosso grupo exclusivo no WhatsApp.
          </p>
        </div>
        <div className={styles.benefitsContainer}>
          <h2 className={styles.benefitsTitle}>Por que entrar no grupo?</h2>
          <ul className={styles.benefitsList}>
            <li><FaUnlock /> Acesso a informações exclusivas sobre a marca</li>
            <li><FaUnlock /> Networking com outros influenciadores</li>
            <li><FaUnlock /> Primeiras notícias sobre campanhas e produtos</li>
            <li><FaUnlock /> Suporte direto da nossa equipe</li>
          </ul>
        </div>
        <a href="https://chat.whatsapp.com/BYffFsb7BihLNuuzdkT3qt" target="_blank" rel="noopener noreferrer" className={styles.whatsappButton}>
          <FaWhatsapp className={styles.whatsappIcon} />
          Entrar no Grupo do WhatsApp
        </a>
        <p className={styles.activationMessage}>
          Após entrar no grupo, sua conta será ativada em até 1 dia útil.
        </p>
      </div>
    </div>
  );
};

export default WhatsAppGatekeeper;