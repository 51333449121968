import axios from 'axios';
import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_DATABASE;

export const fetchUsersAndMetrics = async (stage, page, search, limit) => {
  try {
    const url = `${API_BASE_URL}/admin/users`;
    const response = await axios.get(url, {
      params: { stage, page, limit, search },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar usuários e métricas:', error);
    throw error;
  }
};

export const approveOrRejectUser = async (userId, action) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/admin/users/${userId}/approval`, 
      { action },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    console.error('Erro ao aprovar/rejeitar usuário:', error);
    toast.error('Erro ao aprovar/rejeitar usuário');
    throw error;
  }
};

export const toggleUserStatus = async (userId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/admin/users/${userId}/toggle-status`,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    console.error('Erro ao alternar status do usuário:', error);
    toast.error('Erro ao alternar status do usuário');
    throw error;
  }
};

export const moveInfluencerToNextStage = async (influencerId, userId = null) => {
    try {
      const url = `${API_BASE_URL}/admin/influencer/${influencerId}/update-stage`;
      const response = await axios.put(url, 
        { userId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      toast.success('Influencer movido para o próximo estágio com sucesso');
      return response.data;
    } catch (error) {
      console.error('Erro ao mover influencer para o próximo estágio:', error);
      toast.error('Erro ao mover influencer para o próximo estágio');
      throw error;
    }
};
  
  export const archiveInfluencer = async (influencerId) => {
    try {
      const url = `${API_BASE_URL}/admin/influencer/${influencerId}/archive`;
      const response = await axios.put(url, 
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      toast.success('Influencer arquivado com sucesso');
      return response.data;
    } catch (error) {
      console.error('Erro ao arquivar influencer:', error);
      toast.error('Erro ao arquivar influencer');
      throw error;
    }
};

export const toggleCommunityStatus = async (username, currentStatus) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/admin/user/${username}/edit`,
      { comunidadeWhatsApp: !currentStatus },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    );
    toast.success('Status da comunidade atualizado com sucesso');
    return response.data;
  } catch (error) {
    console.error('Erro ao alternar status da comunidade:', error);
    toast.error('Erro ao alternar status da comunidade');
    throw error;
  }
};