import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

import { useUser, UserProvider, ActionProvider } from "./states";
import ScrollToTop from "./scroll-to-top";
import WhatsAppGatekeeper from './views/whatsapp-gatekeeper';
import Cadastro from "./views/cadastro";
import LP from "./views/lp";
import Home from "./views/home";
import ResetarSenha from "./views/resetar-senha";
import Perfil from "./views/perfil";
import Login from "./views/login";
import NotFound from "./views/not-found";
import Usuarios from "./views/Usuarios/Usuarios";
import Indicators from "./views/indicacoes";
import UserPage from "./views/user-page";
import Dashboard from "./views/dashboard";
import AcademyAdmin from "./views/academy-admin";
import Comunidade from "./views/community";
import PostComments from "./views/post-comments";
import Help from "./views/help";
import AvaliarApp from "./views/avaliar-app";
import AcademyDashboard from './views/academy';
import AulaDetail from './views/aulaDetail';
import Quizz from './views/quizz';
import AvaliacoesAdmin from './views/avaliacoes-admin';
import MinhasVendas from "./views/minhas-vendas";
import VendasAdmin from './views/vendas-admin';
import BotExtrator from "./views/bot-extrator";

const App = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_DATABASE}/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(response => {
      if (response.data.success) {
        setUser(response.data.user);
      } else {
        localStorage.removeItem("token");
      }
      setLoading(false);
    })
    .catch(error => {
      localStorage.removeItem("token");
      console.log(error);
      setLoading(false);
    });
  }, [setUser]);

  if (loading) {
    return (
      <div className="loader-container">
        <img
          alt="L2I701"
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2be29237-57c8-44a6-a051-680d46410acb/2f048f2b-001e-43b0-95b5-c2db3e6d0d27?org_if_sml=14503&amp;force_format=original"
          className="header-l21"
        />
        <ClipLoader size={60} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  const ProtectedRoute = ({ component: Component, adminComponent: AdminComponent, ...rest }) => {
    const isAdmin = user?.type === 'admin';
    const isAuthenticated = !!localStorage.getItem("token");
    
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated) {
            return <Redirect to="/login" />;
          }
          
          if (isAdmin && AdminComponent) {
            return <AdminComponent {...props} />;
          }
          
          if (!isAdmin && !user?.comunidadeWhatsApp) {
            return <WhatsAppGatekeeper />;
          }
          
          return <Component {...props} />;
        }}
      />
    );
  };

  const AvaliarRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => 
        !!localStorage.getItem("token") && !user?.avaliouApp ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route component={Cadastro} exact path="/cadastro/:id" />
        <Route component={LP} exact path="/lp" />
        <Route component={ResetarSenha} exact path="/resetar-senha/:token?" />
        <Route component={Login} exact path="/login" />
        
        <ProtectedRoute component={Perfil} adminComponent={Perfil} exact path="/perfil" />
        <ProtectedRoute adminComponent={UserPage} exact path="/usuario/:username" />
        <ProtectedRoute adminComponent={Usuarios} exact path="/influenciadores" />
        <ProtectedRoute adminComponent={Dashboard} component={Home} exact path="/" />
        <ProtectedRoute component={Indicators} exact path="/indicacoes" />
        <ProtectedRoute component={Comunidade} adminComponent={Comunidade} exact path="/comunidade" />
        <ProtectedRoute component={PostComments} adminComponent={PostComments} exact path="/post/:id" />
        <ProtectedRoute component={Help} adminComponent={Help} exact path="/ajuda" />
        <ProtectedRoute component={AcademyDashboard} adminComponent={AcademyDashboard} exact path="/academy" />
        <ProtectedRoute adminComponent={AcademyAdmin} exact path="/gerenciamento-academy" />
        <ProtectedRoute component={AulaDetail} adminComponent={AulaDetail} exact path="/academy/aula/:lessonId" />
        <ProtectedRoute component={Quizz} adminComponent={Quizz} exact path="/academy/quiz/:quizId" />
        <ProtectedRoute adminComponent={AvaliacoesAdmin} exact path="/avaliacoes" />
        <ProtectedRoute adminComponent={VendasAdmin} exact path="/comissoes" />
        <ProtectedRoute component={MinhasVendas} exact path="/vendas" />
        <ProtectedRoute adminComponent={BotExtrator} exact path="/extrator" />
        
        <AvaliarRoute component={AvaliarApp} exact path="/avaliar" />
        
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
      />
    </Router>
  );
};

const AppWrapper = () => (
  <UserProvider>
    <ActionProvider>
      <App />
    </ActionProvider>
  </UserProvider>
);

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<AppWrapper />);

export default AppWrapper;