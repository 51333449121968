import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Joyride, { STATUS } from 'react-joyride';
import { useUser } from '../states';
import { FaGift, FaTrophy } from 'react-icons/fa';
import styles from './influencer-tutorial.module.css';

const InfluencerTutorial = ({ lastVoucher, showTutorial, setShowTutorial, setShowMonthlyVoucherPopup }) => {
  const { user } = useUser();
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const stepsLevel1 = [
    {
      target: '.generate-monthly-voucher-button',
      content: 'Clique aqui para abrir o popup do voucher mensal de 50% OFF!',
      disableBeacon: true,
    },
    {
      target: '.monthly-voucher-popup .generate-monthly-voucher-button',
      content: 'Agora, clique em "Gerar Voucher" para obter seu cupom de 50% OFF.',
    }
  ];

  const stepsLevel2 = [
    {
      target: '.generate-voucher-button',
      content: 'Clique aqui para ver seu voucher de R$ 400 de desconto!',
      disableBeacon: true,
    },
    {
      target: '.voucher-copy-button',
      content: 'Agora, clique aqui para copiar o código do seu voucher.',
    }
  ];

  const steps = user?.nivel === 'Nível 1' ? stepsLevel1 : stepsLevel2;

  const handleJoyrideCallback = (data) => {
    const { status, action, index, type } = data;
    console.log("Joyride callback:", data);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setShowTutorial(false);
    } else if (user?.nivel === 'Nível 1') {
      if (action === 'next' && index === 0) {
        setShowMonthlyVoucherPopup(true);
        setTimeout(() => {
          setStepIndex(1);
        }, 500);
      } else if (type === 'step:after' && index === 1) {
        const voucherButton = document.querySelector('.monthly-voucher-popup .generate-monthly-voucher-button');
        if (voucherButton) {
          voucherButton.click();
        }
        setRunTour(false);
        setShowTutorial(false);
      }
    } else if (user?.nivel === 'Nível 2') {
      if (type === 'step:after' && index === 0) {
        const voucherButton = document.querySelector('.generate-voucher-button');
        if (voucherButton) {
          voucherButton.click();
        }
        setTimeout(() => {
          setStepIndex(1);
        }, 500);
      } else if (type === 'step:after' && index === 1) {
        const copyButton = document.querySelector('.voucher-copy-button');
        if (copyButton) {
          copyButton.click();
        }
        setRunTour(false);
        setShowTutorial(false);
      }
    }
    setStepIndex(index);
  };

  const startTour = () => {
    console.log("Starting tour");
    setRunTour(true);
    setShowTutorial(false);
  };

  const closeTutorial = () => {
    console.log("Closing tutorial");
    setShowTutorial(false);
    setRunTour(false);
  };

  return (
    <>
      <AnimatePresence>
        {showTutorial && (
          <motion.div
            className={styles.tutorialOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={styles.tutorialContent}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              {user?.nivel === 'Nível 1' ? <FaGift className={styles.icon} /> : <FaTrophy className={styles.icon} />}
              <h2 className={styles.title}>
                {user?.nivel === 'Nível 1' ? 'Parabéns, Influenciador Nível 1!' : 'Parabéns, Influenciador Nível 2!'}
              </h2>
              <p className={styles.description}>
                {user?.nivel === 'Nível 1'
                  ? 'Você foi aprovado como influenciador nível 1! Isso lhe dá direito a comprar produtos unitários da BodyDry a preço de custo com 50% de desconto em compras de até R$ 400, uma vez por mês.'
                  : 'Você foi aprovado como influenciador nível 2! Isso lhe dá direito a um voucher de R$ 400 de desconto para comprar produtos unitários da BodyDry.'}
              </p>
              <button className={styles.button} onClick={startTour}>
                Mostrar como resgatar
              </button>
              <button className={styles.button} onClick={closeTutorial} style={{ marginLeft: '1rem' }}>
                Fechar
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        stepIndex={stepIndex}
        styles={{
          options: {
            primaryColor: '#68b43a',
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default InfluencerTutorial;