import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './user-approval.module.css';

const ApprovalComponent = ({ userId, onClose, onUpdate }) => {
 const [approvalType, setApprovalType] = useState(null);
 const [confirmationText, setConfirmationText] = useState('');
 const [isLoading, setIsLoading] = useState(false);
 const inputRef = useRef(null);

 const CONFIRMATION_PHRASE = 'DESEJO APROVAR ESSE USUÁRIO COM VOUCHER';

 const handleApprovalTypeChange = (type) => {
   setApprovalType(type);
   setConfirmationText('');
 };

 const handleConfirmationTextChange = (e) => {
   setConfirmationText(e.target.value.toUpperCase());
 };

 const handleConfirm = async () => {
   setIsLoading(true);
   try {
     let action;
     switch(approvalType) {
       case 'level1':
         action = 'approve';
         break;
       case 'level2':
         action = 'approve-voucher-200';
         break;
       case 'level3':
         action = 'approve-voucher';
         break;
       default:
         action = 'approve';
     }

     const response = await axios.put(`${process.env.REACT_APP_DATABASE}/admin/users/${userId}/approval`, { action }, {
       headers: {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }
     });
     
     toast.success(response.data.message);
     onClose();
     onUpdate();
   } catch (error) {
     toast.error(error.response?.data?.error || 'An error occurred');
   } finally {
     setIsLoading(false);
   }
 };

 const handleClose = () => {
   setApprovalType(null);
   setConfirmationText('');
   onClose();
 };

 const isConfirmationValid = confirmationText === CONFIRMATION_PHRASE;

 const renderConfirmationInput = () => (
   <div className={styles.confirmationSection}>
     <p className={styles.confirmationPhrase}>
       {CONFIRMATION_PHRASE.split('').map((char, index) => (
         <span
           key={index}
           className={confirmationText[index] === char ? styles.correct : ''}
         >
           {char}
         </span>
       ))}
     </p>
     <div className={styles.confirmationInputContainer}>
       <input
         ref={inputRef}
         type="text"
         value={confirmationText}
         onChange={handleConfirmationTextChange}
         className={styles.confirmationInput}
         placeholder="Digite a frase acima"
         onPaste={(e) => e.preventDefault()}
       />
     </div>
   </div>
 );

 return (
   <div className={styles.approvalPopup}>
     <div className={styles.approvalContainer}>
       <button className={styles.closeButton} onClick={handleClose}>&times;</button>
       <h2 className={styles.title}>Aprovar Usuário</h2>
       <div className={styles.optionsContainer}>
         <button
           className={`${styles.optionButton} ${approvalType === 'level1' ? styles.selected : ''}`}
           onClick={() => handleApprovalTypeChange('level1')}
         >
           Sem Voucher (Nível 1)
         </button>
         <button
           className={`${styles.optionButton} ${approvalType === 'level2' ? styles.selected : ''}`}
           onClick={() => handleApprovalTypeChange('level2')}
         >
           Voucher R$200 (Nível 2)
         </button>
         <button
           className={`${styles.optionButton} ${approvalType === 'level3' ? styles.selected : ''}`}
           onClick={() => handleApprovalTypeChange('level3')}
         >
           Voucher R$400 (Nível 3)
         </button>
       </div>
       {(approvalType === 'level2' || approvalType === 'level3') && renderConfirmationInput()}
       <button
         className={styles.confirmButton}
         onClick={handleConfirm}
         disabled={((approvalType === 'level2' || approvalType === 'level3') && !isConfirmationValid) || approvalType === null || isLoading}
       >
         {isLoading ? 'Processando...' : 'Confirmar Aprovação'}
       </button>
     </div>
   </div>
 );
};

export default ApprovalComponent;